import React, { useContext, useEffect, useReducer } from 'react'
import { Link } from 'gatsby'
import cx from 'classnames'
import { useAtom } from 'jotai'
import dayjs from 'dayjs'
import { useIntl, FormattedMessage } from 'react-intl'

import { userMenuAtom } from '~atoms'
import { useMediaCardData } from '~hooks'
import { getUser, getAgeGroupSlug, isRelatedArticle, hasBeenSeen } from '~utilities'

import { MediaCard } from '~components/shared'

const TrendingQuestions = ({ sidebar }) => {
  const _ = require(`lodash`)
  const [isUserMenuOpen] = useAtom(userMenuAtom)
  const [, forceUpdate] = useReducer(x => x + 1, 0)
  useEffect(() => {
    forceUpdate()
  }, [isUserMenuOpen])

  const { locale } = useIntl()
  const key = locale.toLowerCase()

  const user = getUser()
  const children = user && user?.['custom:childrenDOBs'] ? JSON.parse(user['custom:childrenDOBs']) : null
  const interests = user && user?.['custom:topicInterests'] ? JSON.parse(user['custom:topicInterests']) : null
  const articlesSeen = user && user?.['custom:articlesSeen'] ? JSON.parse(user['custom:articlesSeen']) : null

  const articles = useMediaCardData()
  const articlesWithQuestions = articles.filter(article => article.hasQuestion)
  let filteredArticles = articlesWithQuestions
  const toxicStress = _.find(articlesWithQuestions, {'slug': 'toxic-stress-break-the-cycle'})
  const strongerStarts = _.find(articlesWithQuestions, {'slug': 'introducing-the-stronger-starts-program-toolkit-your-free-guide-to-raising'})

  // console.log(articlesWithQuestions)
  console.log('stronger starts', strongerStarts)

  if (children && children.length > 0) {
    let userAgeGroups = []
    children.map(child => {
      let ageGroup = dayjs().diff(child, 'months') |> getAgeGroupSlug
      userAgeGroups = userAgeGroups.concat([ageGroup])
    })
    filteredArticles = filteredArticles.filter(article => isRelatedArticle(article, userAgeGroups))
  }

  if (interests && interests.length > 0) {
    let userTopics = []
    interests.map(interest => {
      userTopics = userTopics.concat([interest])
    })
    filteredArticles = filteredArticles.filter(article => isRelatedArticle(article, userTopics))
  }

  if (articlesSeen && articlesSeen.length > 0) {
    filteredArticles = filteredArticles.filter(article => !hasBeenSeen(article, articlesSeen))
  }

  const shuffledArticles = _.shuffle(!user || filteredArticles.length === 0 ? articlesWithQuestions : filteredArticles)

  return (
    <div className={cx('flex flex-col bg-gray-light', sidebar ? 'px-4' : 'all-center py-8')}>
      <div className={cx('flex flex-col w-full', !sidebar && 'max-w-page mt-4 lg:px-32')}>
        <MediaCard
          question={toxicStress.question}
          displayStyle='question'
          url={toxicStress.url}
        />
        <MediaCard
          question={strongerStarts.question}
          displayStyle='question'
          url={strongerStarts.url}
        />
        {shuffledArticles.slice(0, 6).map(article => (
          <MediaCard
            key={article.id}
            question={article.question}
            displayStyle='question'
            url={article.url}
            sidebar={sidebar}
          />
        ))}
      </div>
      <div className={cx(sidebar ? 'mt-4' : 'mt-8')}>
        <div className='button button-pink'>
          <Link to={`/${key}/faqs/`}>
            <FormattedMessage id='see-all-questions' /> <em className='fas fa-caret-right ml-2' />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default TrendingQuestions
