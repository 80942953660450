import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import LazyLoad from 'react-lazyload'

import { useEffectOnce } from '~hooks'

import { ClientOnly } from '~components/shared'

const SocialMedia = () => {
  const data = useStaticQuery(staticQuery)
  const { settings } = data
  const { instagramLink, pinterestLink } = settings

  useEffectOnce(() => {
    const script = document.createElement('script')
    script.async = true
    script.type = 'text/javascript'
    script.dataset.pinBuild = 'doBuild'
    script.src = '//assets.pinterest.com/js/pinit.js'
    document.body.appendChild(script)
    if (window.doBuild) {
      window.doBuild()
    }
  })

  return (
    <div className='flex flex-col lg:flex-row'>
      <div className='flex flex-col items-center lg:w-1/2 mb-8 lg:mb-0 lg:pr-4'>
        <div className='headline-5 text-center'>
          First 5 California Instagram <em className='fab fa-instagram text-instagram' />
        </div>
        <a href={instagramLink} target='_blank' rel='noopener noreferrer' className='headline-6 text-blue mb-8'>
          @First5California
        </a>
        <ClientOnly>
          <LazyLoad offset={100} once>
            <div className='instagram-widget'>
              <iframe
                src='https://snapwidget.com/embed/768542'
                frameBorder='0'
                scrolling='no'
                title='First 5 California Instagram'
              />
            </div>
          </LazyLoad>
        </ClientOnly>
      </div>
      <div className='flex flex-col items-center lg:w-1/2 lg:pl-4'>
        <div className='headline-5 text-center'>
          First 5 California Pinterest <em className='fab fa-pinterest-square text-pinterest' />
        </div>
        <a href={pinterestLink} target='_blank' rel='noopener noreferrer' className='headline-6 text-blue mb-8'>
          @first5ca
        </a>
        <div className='pinterest-widget'>
          <a
            data-pin-do='embedUser'
            data-pin-board-width='640'
            data-pin-scale-height='592'
            data-pin-scale-width='80'
            href='https://www.pinterest.com/first5ca/'
            className='text-blue'
          />
        </div>
      </div>
    </div>
  )
}

export default SocialMedia

const staticQuery = graphql`
  {
    settings: contentfulSettings(title: { eq: "Global Settings" }) {
      instagramLink
      pinterestLink
    }
  }
`
