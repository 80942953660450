import React from 'react'
import { Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'

import { useMediaCardData } from '~hooks'

const Featured = ({ articleId }) => {
  const featuredArticle = useMediaCardData(articleId)

  return (
    <>
      <div className='headline-section-centered mt-16 mb-16'>
        <div>
          <FormattedMessage id='featured-this-month' />
        </div>
        <hr aria-hidden />
      </div>
      <div className='flex flex-col md:px-16 lg:px-0 lg:flex-row'>
        <Link to={featuredArticle.url} className='lg:w-1/2 lg:pr-4' title={featuredArticle.title} aria-hidden>
          <img
            src={featuredArticle.image + '?fm=jpg&fl=progressive&w=960&q=80'}
            alt={featuredArticle.caption}
            className='w-full h-auto mb-8'
          />
        </Link>
        <div className='flex flex-col justify-center lg:w-1/2 lg:pl-4'>
          <div className='headline-3 mb-2 text-blue'>{featuredArticle.title}</div>
          <p className='body-4 mb-8 text-clamp-5'>{featuredArticle.caption}</p>
          <div>
            <Link to={featuredArticle.url} className='button button-pink'>
              <div>
                <FormattedMessage id='read-more' /> <em className='fas fa-caret-right ml-2' />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Featured
