import React from 'react'
import { useIntl } from 'react-intl'

import { getURI } from '~utilities'

import { NavCard } from '~components/shared'

const NavCards = props => {
  const { locale } = useIntl()
  const key = locale.toLowerCase()
  const navCardClasses = 'md:w-1/2 lg:w-1/4 my-4 px-4'

  return (
    <>
      <div className={navCardClasses}>
        <NavCard
          color='purple'
          title={props.Hero_NavCard1_Headline}
          caption={props.Hero_NavCard1_Subline}
          uri={props.Hero_NavCard1_ExternalLink}
        />
      </div>
      <div className={navCardClasses}>
        <NavCard
          color='orange'
          title={props.Hero_NavCard2_Headline}
          caption={props.Hero_NavCard2_Subline}
          uri={getURI(props.Hero_NavCard2_InternalLink, key)}
        />
      </div>
      <div className={navCardClasses}>
        <NavCard
          color='green'
          title={props.Hero_NavCard3_Headline}
          caption={props.Hero_NavCard3_Subline}
          uri={getURI(props.Hero_NavCard3_InternalLink, key)}
        />
      </div>
      <div className={navCardClasses}>
        <NavCard
          color='blue'
          title={props.Hero_NavCard4_Headline}
          caption={props.Hero_NavCard4_Subline}
          uri={getURI(props.Hero_NavCard4_InternalLink, key)}
        />
      </div>
    </>
  )
}

export default NavCards
